import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() =>
      import('@iso/containers/Application/Onboarding/SignIn/SignIn')
    ),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/containers/Pages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/containers/Pages/500/500')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() =>
      import('@iso/containers/Application/Onboarding/SignIn/SignIn')
    ),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() =>
      import('@iso/containers/Application/Onboarding/SignUp/SignUp')
    ),
  },
  {
    path: PUBLIC_ROUTE.EMAIL_VERIFICATION,
    component: lazy(() =>
      import(
        '@iso/containers/Application/Onboarding/EmailVerification/EmailVerification'
      )
    ),
  },
  {
    path: PUBLIC_ROUTE.VERIFY_ACCOUNT,
    component: lazy(() =>
      import(
        '@iso/containers/Application/Onboarding/VerifyAccount/VerifyAccount'
      )
    ),
  },
  {
    path: PUBLIC_ROUTE.TERMS,
    component: lazy(() =>
      import(
        '@iso/containers/Application/Onboarding/TermsConditions/TermsConditions'
      )
    ),
  },
  {
    path: PUBLIC_ROUTE.PRIVACY_POLICY,
    component: lazy(() =>
      import(
        '@iso/containers/Application/Onboarding/PrivacyPolicy/PrivacyPolicy'
      )
    ),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import(
        '@iso/containers/Application/Onboarding/ForgotPassword/ForgotPassword'
      )
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import(
        '@iso/containers/Application/Onboarding/ResetPassword/ResetPassword'
      )
    ),
  },
  {
    path: PUBLIC_ROUTE.AUTH0_CALLBACK,
    component: lazy(() =>
      import('@iso/containers/Authentication/Auth0/Auth0Callback')
    ),
  },
];
function PrivateRoute({ children, ...rest }) {
  //const isLoggedIn = useSelector((state) => state.Auth.idToken);

  const isLoggedIn = localStorage.getItem('token');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/app">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
